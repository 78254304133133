<template>
  <div>
    <h1 class="font-zeyada text-4xl my-4 text-center">{{ product.titulo }}</h1>

    <div
      class="
        w-12
        h-12
        mx-auto
        my-10
        border-2 border-yellow-500
        rounded-full
        loader
      "
      v-if="!isLoaded"
    ></div>

    <TransitionRoot
      :show="isLoaded"
      enter="transition-opacity duration-500"
      enter-from="opacity-0"
      enter-to="opacity-100"
    >
      <div class="space-y-4 mb-6" v-if="isLoaded">
        <div class="aspect-w-4 aspect-h-3">
          <img
            class="object-cover shadow-lg rounded-lg"
            :src="API_BASE_URL + product?.foto?.url"
            :alt="product.titulo"
          />
        </div>
      </div>

      <img
        v-for="image in product.galeria"
        :src="API_BASE_URL + image?.url"
        :key="image.id"
        alt=""
        class="rounded-lg shadow-lg mt-6"
      />
    </TransitionRoot>
  </div>
</template>

<script>
import { ref } from "vue";
import { TransitionRoot } from "@headlessui/vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { API_BASE_URL } from "@/config/parameters";

export default {
  name: "Producto",
  components: { TransitionRoot },
  setup() {
    const route = useRoute();
    const product = ref({});
    const isLoaded = ref(false);

    fetch(
      `${API_BASE_URL}/productos?slug=${route.params.slug}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 0) {
          router.push({ name: "NotFound", params: { catchAll: "404" } });
        }
        product.value = Object.assign({}, data[0]);
        isLoaded.value = true;
      })
      .catch((error) => console.error(error));

    return {
      API_BASE_URL,
      product,
      isLoaded,
    };
  },
};
</script>
